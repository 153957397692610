.grid-label {
  display: flex;
}
.grid-label-value {
  margin-right: 5px;
}
.grid-label-difference {
  display: flex;
  align-items: center;
  font-size: 8px;
}
.grid-label-arrow {
  font-size: 12px;
  margin: 0px 3px;
}
.grid-label-up-arrow {
  color: green;
}
.grid-label-down-arrow {
  color: red;
}
.grid-filter-container {
  background-color: #2C3436;
  color: #1C1F20;
  width: 200px;
  padding: 5px;
}
.grid-filter-container > div {
  margin-bottom: 5px;
}
.grid-filter-container.grid-filter-menu-open {
  height: 300px;
}
.grid-filter-select {
  height: 350px;
}
.grid-filter-footer {
  display: flex;
  justify-content: space-evenly;
}
.ag-header-cell .ag-react-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.ag-header-cell .ag-react-container .grid-header-cell {
  display: flex;
  flex: 1;
}
.ag-header-cell .ag-react-container .grid-header-cell-sortable-container {
  display: flex;
  flex: 1;
  justify-content: space-between;
}
.ag-header-cell .ag-react-container .grid-header-cell-filter-icon-active {
  color: #70587C;
}
.ag-header-cell .ag-react-container .grid-header-cell-filter-icon-inactive {
  color: #F5F5F5;
}
.ag-header-cell .ag-react-container .grid-header-cell-sort-icon {
  color: #70587C;
}
.navbar {
  background-color: #1C1F20;
  height: 32px;
  padding: 5px 0px;
  box-shadow: 0px 0px 3px 1px #70587C;
}
.navbar-routes {
  padding-left: 40px;
}
.navbar a {
  outline: none;
}
a {
  color: #F5F5F5;
  text-decoration: none;
  transition: 0.3s;
}
a:hover {
  color: #70587C;
}
.btn {
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  height: 30px;
  border-radius: 3px;
  outline: none;
  padding: 4px 10px;
  cursor: pointer;
  transition: 0.3s;
}
.btn:hover {
  box-shadow: 0px 0px 3px 1px;
}
.btn:active {
  animation-name: button-clicked;
  animation-duration: 0.3s;
}
.btn-default {
  background-color: white;
  color: #70587C;
}
.btn-primary {
  background-color: #70587C;
  color: #F5F5F5;
}
.btn-danger {
  background-color: #bf0000;
  color: #F5F5F5;
}
.btn-disabled {
  background-color: #F5F5F5;
  color: #1C1F20;
  cursor: not-allowed;
}
.btn-disabled:hover {
  box-shadow: none;
}
.btn-rounded-edge {
  border-radius: calc(30px / 2);
}
@keyframes button-clicked {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}
.input-container {
  display: flex;
  flex-grow: 1;
  height: 32px;
  position: relative;
}
.input {
  border: 1px solid #70587C;
  font-size: 14px;
  transition: 0.3s;
  padding: 12px !important;
  flex-grow: 1;
  border-radius: 15px;
  background-color: #F5F5F5 !important;
  color: #1C1F20 !important;
}
.input:focus {
  outline: none;
  border: none;
  -webkit-appearance: none;
  box-shadow: 0px 0px 10px 1px #1C1F20;
}
.input-suffix {
  position: absolute;
  right: 12px;
  font-size: 20px;
  color: #70587C;
  top: calc(50% - 10px);
}
#routes-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px;
}
.rankings-view-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  align-self: center;
}
.rankings-view-container .rankings-select-container {
  width: 300px;
  align-self: center;
  margin-bottom: 10px;
}
.rankings-last-update {
  align-self: center;
}
.team-portal-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.team-portal-container .team-portal-header {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
}
.team-portal-container .team-portal-header div:not(.team-portal-header-title) {
  font-size: 12px;
}
.team-portal-container .team-portal-body {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.about-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  align-self: center;
  max-width: 400px;
}
.about-container a {
  color: #70587C;
  transition: 0.3s;
}
.about-container a:hover {
  color: #2C3436;
}
/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 25px;
  height: 20px;
  left: 10px;
}
/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #F5F5F5;
  transition: 0.3s;
}
/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #70587C;
}
/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}
/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}
/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}
/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}
/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}
/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}
/* Individual item */
.bm-item {
  display: inline-block;
}
/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  font-family: 'Nunito Sans', sans-serif;
  color: #2C3436;
}
.flex-column {
  display: flex;
  flex-direction: column;
  flex: 1;
}
#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.header-title {
  font-size: 24px;
}
.label {
  font-weight: bold;
}
